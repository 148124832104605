import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as globals from '../globals';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  public content = `
  <h1>Adatkezelési Szabályzat</h1><p>1. Szabályzat célja, hatálya</p>
  <p>1.1. Célja</p>
  <p>Jelen szabályzat célja, hogy a Dr. Bánkuti Gábor egyéni ügyvéd mitkezelnekrolam.dewings.hu honlapon, mint Adatkezelő, a zajló adatkezelést, tiszteletben tartsa a természetes személyek magánszféráját és jogait. Az adatkezelésnek meg kell felelni az információs önrendelkezés alkotmányos alapelvének, továbbá a megkapott adatokkal visszaélés ne történjen az Adatkezelő birtokába jutott szenzitív információk tekintetében.</p>
  <p>1.2. Hatálya</p>
  <p>E szabályzat hatálya kiterjed Dr. Bánkuti Gábor egyéni ügyvéd székhelyére, a székhely és fiókiroda közötti információáramlásra, a webfelületen közölt információkra. A Szabályzat tárgy hatálya kiterjed az Irodánál és a hozzá kapcsolódó kezelt valamennyi személyes adatra, függetlenül azok megjelenési formájától, illetve fellelési helyétől. Ezen szabályzat alkalmazandó az adatkezelés összes fázisára, továbbá kiterjed az Iroda valamennyi alkalmazottjára.</p>
  <p>2. Jogszabályi hivatkozás</p>
  <p>Jelen szabályzat megalkotásához az alábbi jogszabályok alapján került sor:</p>
  <ul>
  <li>Infotv.: 2011. évi CXII. törvény az információs önrendelkezési jogról és az információszabadságról;</li>
  <li>Üttv.: 2017. évi LXXVIII. törvény az ügyvédi tevékenységről;</li>
  <li>GDPR: Európai Parlament és Tanács 2016/679 sz. rendelete az általános adatvédelmi rendelet</li>
  </ul>
  <p>A Szabályzat az Irodám és a weblapon megadott személyes adatok kezeléséhez került megalkotásra</p>
  <p>3. Értelmező rendelkezések</p>
  <p>Az Infotv. 3. § 2. pontjában meghatározottak szerint „személyes adat: az érintettel kapcsolatba hozható adat – különösen az érintett neve, azonosító jele, valamint egy vagy több fizikai, fiziológiai, mentális, gazdasági, kulturális vagy szociális azonosságára jellemző ismeret -, valamint az adatból levonható, az érintettre vonatkozó következtetés.”</p>
  <p>Az Infotv. 3. § 7. pontjában foglaltak szerint „hozzájárulás: az érintett akaratának önkéntes és határozott kinyilvánítása, amely megfelelő tájékoztatáson alapul, és amellyel félreérthetetlen beleegyezését adja a rá vonatkozó személyes adatok – teljes körű vagy egyes műveletekre kiterjedő – kezeléséhez.”</p>
  <p>Az Infotv. 3. § 9. pontja kimondja, hogy „adatkezelő: az a természetes vagy jogi személy, illetve jogi személyiséggel nem rendelkező szervezet, aki vagy amely önállóan vagy másokkal együtt az adatok kezelésének célját meghatározza, az adatkezelésre (beleértve a felhasznált eszközt) vonatkozó döntéseket meghozza és végrehajtja, vagy az általa megbízott adatfeldolgozóval végrehajtatja.”</p>
  <p>Az Infotv. 3. § 10. pontja kimondja, hogy „adatkezelés: az alkalmazott eljárástól függetlenül az adatokon végzett bármely művelet vagy a műveletek összessége, így különösen gyűjtése, felvétele, rögzítése, rendszerezése, tárolása, megváltoztatása, felhasználása, lekérdezése, továbbítása, nyilvánosságra hozatala, összehangolása vagy összekapcsolása, zárolása, törlése és megsemmisítése, valamint az adatok további felhasználásának megakadályozása, fénykép-, hang vagy képfelvétel készítése</p>
  <p>Az Infotv 3. § 22. pontja értelmében „harmadik személy: olyan természetes vagy jogi személy, illetve jogi személyiséggel nem rendelkező szervezet, aki vagy amely nem azonos az érintettel, az adatkezelővel vagy az adatfeldolgozóval.</p>
  <p>4. Adatvédelem</p>
  <p>4.1. Elvek, szabályok</p>
  <p>4.1.2. Adatkezelés elvei, szabályai Személyes adat kizárólag meghatározott célból, jog gyakorlása és kötelezettség teljesítése érdekében kezelhető. Az adatkezelésnek minden szakaszában meg kell felelnie az adatkezelés céljának, az adatok felvételének és kezelésének tisztességesnek és törvényesnek kell lennie.</p>
  <p>Csak olyan személyes adat kezelhető, amely az adatkezelés céljának megvalósulásához elengedhetetlen, a cél elérésére alkalmas. A személyes adat csak a cél megvalósulásához szükséges mértékben és ideig kezelhető.</p>
  <p>A személyes adat az adatkezelés során mindaddig megőrzi e minőségét, amíg kapcsolata az érintettel helyreállítható. Az érintettel akkor helyreállítható a kapcsolat, ha az adatkezelő rendelkezik azokkal a technikai feltételekkel, amelyek a helyreállításhoz szükségesek.</p>
  <p>Az adatkezelés során biztosítani kell az adatok pontosságát, teljességét és - ha az adatkezelés céljára tekintettel szükséges - naprakészségét, valamint azt, hogy az érintettet csak az adatkezelés céljához szükséges ideig lehessen azonosítani.</p>
  <p>Adatkezelést Irodánk csak akkor végez, ha</p>
  <ul>
  <li>ahhoz az érintett hozzájárul - weblap esetében a fórumhoz történő hozzászólás, kérdés feltevés; - jogi ügyek rendezése esetén a szükséges személyes adatok bekérése, vagy</li><li>azt törvény vagy – törvény felhatalmazása alapján, az abban meghatározott körben – helyi önkormányzat rendelete közérdeken alapuló célból elrendeli (a továbbiakban: kötelező adatkezelés) (az Üttv. és az ügyvédi tevékenységhez kapcsolódó jogszabályok alapján).</li></ul>
  <p>Személyes adat kezelhető akkor is, ha az érintett hozzájárulásának beszerzése lehetetlen vagy aránytalan költséggel járna, és a személyes adat kezelése&nbsp;</p>
  <p>a) az adatkezelőre vonatkozó jogi kötelezettség teljesítése céljából szükséges, vagy&nbsp;</p>
  <p>b) az adatkezelő vagy harmadik személy jogos érdekének érvényesítése céljából szükséges, és ezen érdek érvényesítése a személyes adatok védelméhez fűződő jog korlátozásával arányban áll.</p>
  <p>Ha a hozzájáruláson alapuló adatkezelés célja az adatkezelővel írásban kötött szerződés végrehajtása, a szerződésnek tartalmaznia kell minden olyan információt, amelyet a személyes adatok kezelése szempontjából - e törvény alapján - az érintettnek ismernie kell, így különösen a kezelendő adatok meghatározását, az adatkezelés időtartamát, a felhasználás célját, az adatok továbbításának tényét, címzettjeit, adatfeldolgozó igénybevételének tényét. A szerződésnek félreérthetetlen módon tartalmaznia kell, hogy az érintett aláírásával hozzájárul adatainak a szerződésben meghatározottak szerinti kezeléséhez.</p>
  <p>4.2 Érintettek jogai</p>
  <p>Az érintettel az adatkezelés megkezdése előtt közölni kell, hogy az adatkezelés hozzájáruláson alapul vagy kötelező.</p>
  <p>Az érintettet az adatkezelés megkezdése előtt egyértelműen és részletesen tájékoztatni kell az adatai kezelésével kapcsolatos minden tényről, különösen az adatkezelés céljáról és jogalapjáról, az adatkezelésre és az adatfeldolgozásra jogosult személyéről, az adatkezelés időtartamáról, illetve arról, hogy kik ismerhetik meg az adatokat. A tájékoztatásnak ki kell terjednie az érintett adatkezeléssel kapcsolatos jogaira és jogorvoslati lehetőségeire is. Az érintett kérelmezheti az adatkezelőnél&nbsp;</p>
  <p>a) tájékoztatását személyes adatai kezeléséről,&nbsp;</p>
  <p>b) személyes adatainak helyesbítését, valamint&nbsp;</p>
  <p>c) személyes adatainak - a kötelező adatkezelés kivételével - törlését vagy zárolását.</p>
  <p>Az érintett tiltakozhat személyes adatának kezelése ellen,</p>
  <ul>
  <li>ha a személyes adatok kezelése vagy továbbítása kizárólag az adatkezelőre vonatkozó jogi kötelezettség teljesítéséhez vagy az adatkezelő, adatátvevő vagy harmadik személy jogos érdekének érvényesítéséhez szükséges, kivéve kötelező adatkezelés esetén;</li>
  <li>ha a személyes adat felhasználása vagy továbbítása közvetlen üzletszerzés, közvélemény-kutatás vagy tudományos kutatás céljára történik; valamint</li><li>törvényben meghatározott egyéb esetben</li></ul><p>Az adatkezelő a tiltakozást a kérelem benyújtásától számított legrövidebb időn belül, de legfeljebb 15 napon belül megvizsgálja, annak megalapozottsága kérdésében döntést hoz, és döntéséről a kérelmezőt írásban tájékoztatja.</p>
  <p>A weblap esetében a fórumhoz történő moderáció illetve a feltett kérdés esetén a válaszadás befejezéséig. Az adatot (e-mail cím, név) maximum 1 évig anonimizáltan kerülnek tárolása, amennyiben a megadandó adat visszavezethető a személyhez.</p>
  <p>Jogi ügyek megbízása esetén a megadott személyes adatokat jogszabályi felhatalmazás alapján kezeljük</p>
  <p>5. Adatbiztonság</p>
  <p>Az adatkezelő köteles az adatkezelési műveleteket úgy megtervezni és végrehajtani, hogy biztosítsa az érintettek magánszférájának védelmét.</p>
  <p>Az Adatkezelő gondoskodik az adatok biztonságáról, megteszi azokat a technikai és szervezési intézkedéseket és kialakítja azokat az eljárási szabályokat, amelyek az Infotv. érvényre juttatásához szükségesek.</p>
  <p>Irodám gondoskodik az adatok jogosulatlan hozzáférés elkerülése érdekében, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés és sérülés, továbbá az alkalmazott technika megváltozásából fakadó hozzáférhetetlenné válás ellen.</p>
  <p>Irodám biztosítja, hogy az érintett adatokba kizárólag a feladat ellátásában közreműködő alkalmazottak nyerjenek betekintést. Valamint Irodám rendelkezik megfelelő védelemmel az egyes számítógépek illetve a számítógépes hálózat egészére vonatkozóan.</p>
  <p>6. Titoktartási kötelezettség</p>
  <p>Iroda alkalmazottai vállalják, hogy a feladatvégzésük során tudomásukra jutott személyes adatokat időbeli korlátozás nélkül megőrzik. A munkavállalók kötelezettséget vállalnak arra, hogy az érintett adatokat kizárólag feladataik teljesítése során használják fel, illetve harmadik személyeknek nem adják ki azokat.</p>
  <p>7. Jogellenes adatkezelés következményei</p>
  <p>7.1 Nemzeti Adatvédelmi és Információszabadság Hatóság előtti eljárás</p>
  <p>Bárki vizsgálatot kezdeményezhet a Hatóságnál bejelentéssel arra hivatkozással, hogy személyes adatok kezelésével, illetve a közérdekű adatok vagy a közérdekből nyilvános adatok megismeréséhez fűződő jogok gyakorlásával kapcsolatban jogsérelem következett be, vagy annak közvetlen veszélye fennáll. A Hatóság a bejelentés alapján lefolytatott vizsgálatról jelentést készíthet, ha az ügyben a Hatóság által hatósági eljárás vagy bírósági eljárás megindítására nem került sor. A személyes adatok védelméhez való jog érvényesülése érdekében a Hatóság adatvédelmi hatósági eljárást indíthat, illetve indít. Az eljárás hatósági határozattal járul.</p>
  <p>7.2 Bírósági jogérvényesítés</p>
  <p>Az érintett a jogainak megsértése esetén az adatkezelő ellen bírósághoz fordulhat.</p>
  <p>8. Záró rendelkezések</p>
  <p>E Szabályzat 2018. március 27. napján lép hatályba.</p>
  <p>Dr. Bánkuti Gábor egyéni ügyvéd</p>
  `;

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    this.http.get(globals.URL + '/getdata?id=3', { responseType: 'text' })
    .subscribe(getDataResult => {
      this.content = getDataResult.toString();
    }, error => {
      console.log(error);
    });
  }

}
