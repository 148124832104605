
export class RegistrationData {
  public companyname: string;
  public email: string;
  public password: string;
  public passwordagain: string;
  public contractnumber: string;
  public privacyPolicy: boolean;

  public RegistrationData() {
    this.privacyPolicy = false;
  }
}
