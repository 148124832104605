import { Component, OnInit, ViewChild } from '@angular/core';
import * as globals from '../globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public emailAddress: string;

  @ViewChild(PopupComponent) PopupComponent: PopupComponent;

  constructor(private http: HttpClient) {
  }

  ngOnInit() {

  }

  public sendEmailClick() {
    const username_json: any = JSON.stringify({ username: this.emailAddress });
    this.http.post(globals.URL + '/forgotpassword', username_json)
      .subscribe(forgotPasswordResult => {
        if (forgotPasswordResult['status'] === 'OK') {
          this.emailAddress = '';
          this.PopupComponent.popup(forgotPasswordResult['message']);
        } else if (forgotPasswordResult['status'] === 'ERROR') {
          this.PopupComponent.popup(forgotPasswordResult['errors']);
        }
      }, error => {
        this.PopupComponent.popup('Szerver hiba.');
      });
  }

}
