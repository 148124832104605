import { Component, OnInit, ViewChild } from '@angular/core';
import { PopupComponent } from '../popup/popup.component';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as globals from '../globals';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  public newPassword: string;
  public newPasswordAgain: string;
  public id: string;

  @ViewChild(PopupComponent) PopupComponent: PopupComponent;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'] ? params['id'] : '';
    });
  }

  public changeClick() {
    if (this.newPassword !== this.newPasswordAgain) {
      this.PopupComponent.popup('A JELSZÓ ÉS A JELSZÓ ÚJRA NEM EGYEZIK!');
    } else if (/[a-z]/.test(this.newPassword) === false ) {
      this.PopupComponent.popup('A JELSZÓNAK TARTALMAZNIA KELL EGY KIS BETŰS KARAKTERT IS!');
    } else if (/[A-Z]/.test(this.newPassword) === false ) {
      this.PopupComponent.popup('A JELSZÓNAK TARTALMAZNIA KELL EGY NAGY BETŰS KARAKTERT IS!');
    } else if (/[1-9]/.test(this.newPassword) === false ) {
      this.PopupComponent.popup('A JELSZÓNAK TARTALMAZNIA KELL EGY SZÁMOT IS!');
    } else if (/[!@#$%^&*]/.test(this.newPassword) === false ) {
      this.PopupComponent.popup('A JELSZÓNAK TARTALMAZNIA KELL EGY SPECIÁLIS KARAKTERT IS!');
    } else {
      const password_change_json: any = JSON.stringify({ password: this.newPassword });
      if (this.id) {
        const this_ = this;
        this.http.post( globals.URL + '/changepassword?id=' + this.id , password_change_json )
        .subscribe(changePasswordResult => {
          if (changePasswordResult['status'] === 'OK') {
            this_.PopupComponent.popup(changePasswordResult['message']).then(function () {
              this_.router.navigateByUrl('/companies');
            }).catch(function () {
              this_.router.navigateByUrl('/companies');
            });
          }
        }, error => {
          this.PopupComponent.popup('Szerver hiba.');
        });
      } else {
        this.PopupComponent.popup('Nem megfelelő paraméter az URL címében!');
      }
    }
  }

}
