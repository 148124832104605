import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { CompaniesComponent } from './companies/companies.component';
import { ClientsComponent } from './clients/clients.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdTypeaheadBasicComponent } from './typeahead-basic/typeahead-basic';
import { FormsModule, ReactiveFormsModule } from '../../node_modules/@angular/forms';
import { AuthService } from './auth.service';
import { CompanyDataComponent } from './company-data/company-data.component';
import { PopupComponent } from './popup/popup.component';
import { CompanyService } from './company.service';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { SuccessRegistrationComponent } from './success-registration/success-registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'companies',
    component: CompaniesComponent
  },
  {
    path: 'clients',
    component: ClientsComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: 'impressum',
    component: ImpressumComponent
  },
  {
    path: 'success-registration',
    component: SuccessRegistrationComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CompaniesComponent,
    ClientsComponent,
    NgbdTypeaheadBasicComponent,
    CompanyDataComponent,
    PopupComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    ImpressumComponent,
    SuccessRegistrationComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent
  ],
  imports: [
    FormsModule, BrowserModule , RouterModule.forRoot(routes), NgbModule.forRoot(),
    ReactiveFormsModule, HttpClientModule, HttpModule
  ],
  providers: [ { provide: LocationStrategy, useClass: HashLocationStrategy }, AuthService, CompanyService ],
  bootstrap: [ AppComponent ],
  exports: [ ]
})
export class AppModule { }

