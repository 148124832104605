import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isLogined: boolean;
  public companyName: string;

  public token: string;

  constructor() {
    this.isLogined = false;
    this.companyName = '';
    const currentuser: string = localStorage.getItem('currentUser');
    if (currentuser) {
      const userNameAndToken: any = JSON.parse(currentuser);
      if (userNameAndToken['token']) {
        this.token = userNameAndToken['token'];
        this.isLogined = true;
      }
    }
  }

}
