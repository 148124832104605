import { Component, ViewChild, OnInit } from '@angular/core';
import { Observable, Subject, merge, of } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService, CompanyResult } from '../company.service';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-ngbd-typeahead-basic',
  templateUrl: './typeahead-basic.html',
  styles: [`.form-control { width: 300px; }`]
})
export class NgbdTypeaheadBasicComponent implements OnInit {

  public model: any;
  public selectedCompany: CompanyResult;

  constructor(private companyService: CompanyService)  {
    this.selectedCompany = null;
  }

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  searching = false;
  searchFailed = false;

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term =>

      this.companyService.search(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        }))

    ),
    tap(() => this.searching = false)
  )

  formatter = (x: {companyname: string}) => x.companyname;

  ngOnInit() {
    this.selectedCompany = null;
  }

  public selectItem(event) {
    const selected = event.item;
    if (selected.id !== -1) {
      for (const key in selected) {
        if (selected.hasOwnProperty(key)) {
          if (selected[key] === 'true' || selected[key] === 'false') {
            selected[key] = ( selected[key] === 'true' ? true : false);
          } else {
            selected[key] = selected[key];
          }
        }
      }
      this.selectedCompany = selected;
    } else {
      event['preventDefault']();
    }
  }

  /*public searchIconClick() {
    var a = this.model;
    this.searchFailed = false;
    var c = this.instance.container;
    this.companyService.manSearch(a);
  }*/

}
