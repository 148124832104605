import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupComponent } from '../popup/popup.component';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as globals from '../globals';
import { UserData } from '../models/UserData';
import { RegistrationData } from '../models/RegistrationData';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {

  public authService: AuthService;
  public user: UserData;
  public registration: RegistrationData;

  @ViewChild(PopupComponent) PopupComponent: PopupComponent;

  constructor(private router: Router, private auth: AuthService,
     private modalService: NgbModal, private http: HttpClient) {
    this.authService = auth;
    this.user = new UserData();
    this.registration = new RegistrationData();
  }

  ngOnInit() {
    // TODO: check is login
  }

  loginClick() {
    const login_json: any = JSON.stringify(this.user);

    this.http.post(globals.URL + '/login', login_json)
      .subscribe(loginResult => {
       if (loginResult['status'] === 'OK') {
        this.auth.token = loginResult['token'];
        this.auth.isLogined = true;
        localStorage.setItem('currentUser',
          JSON.stringify({ username: this.user.username, token: this.auth.token  }));
         // this.router.navigateByUrl('/');
       } else if (loginResult['status'] === 'ERROR') {
        this.PopupComponent.popup(loginResult['errors']);
       }
      }, error => {
        this.PopupComponent.popup('Szerver hiba.');
      });
  }

  public registrationClick() {
    if (this.registration.password !== this.registration.passwordagain) {
      this.PopupComponent.popup('A JELSZÓ ÉS A JELSZÓ ÚJRA NEM EGYEZIK!');
    } else if (/[a-z]/.test(this.registration.password) === false ) {
      this.PopupComponent.popup('A JELSZÓNAK TARTALMAZNIA KELL EGY KIS BETŰS KARAKTERT IS!');
    } else if (/[A-Z]/.test(this.registration.password) === false ) {
      this.PopupComponent.popup('A JELSZÓNAK TARTALMAZNIA KELL EGY NAGY BETŰS KARAKTERT IS!');
    } else if (/[1-9]/.test(this.registration.password) === false ) {
      this.PopupComponent.popup('A JELSZÓNAK TARTALMAZNIA KELL EGY SZÁMOT IS!');
    } else if (/[!@#$%^&*]/.test(this.registration.password) === false ) {
      this.PopupComponent.popup('A JELSZÓNAK TARTALMAZNIA KELL EGY SPECIÁLIS KARAKTERT IS!');
    } else if (!this.registration.privacyPolicy) {
      this.PopupComponent.popup('A REGISZTRÁCIÓ BEFEJEZÉSÉHEZ, KÉRJÜK FOGADJA EL AZ ADATKEZELÉSI TÁJÉKOZTATÓT!');
    } else {
      const reg_json: any = JSON.stringify(this.registration);

      this.http.post( globals.URL + '/registration', reg_json )
        .subscribe(saveResult => {
          if (saveResult['status'] === 'OK') {
            this.router.navigateByUrl('/success-registration');
          } else {
            const message = saveResult['errors'].toString();
            this.PopupComponent.popup(message);
          }
        }, error => {
          this.PopupComponent.popup('Szerver hiba.');
        });

    }
  }

  public upload(htmlString: string) {
    this.PopupComponent.popup(htmlString, true);
  }

  public popup(text: string) {
    this.PopupComponent.popup(text, false);
  }

}
