import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbdTypeaheadBasicComponent } from '../typeahead-basic/typeahead-basic';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  @ViewChild(NgbdTypeaheadBasicComponent) basicComp: NgbdTypeaheadBasicComponent;

  constructor() {
  }

  ngOnInit() {
  }

}
