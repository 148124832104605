import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as globals from '../globals';
import { Router } from '@angular/router';
import { CompanyData } from '../models/CompanyData';
import { DateNumbers } from '../models/DateNumbers';

@Component({
  selector: 'app-company-data',
  templateUrl: './company-data.component.html',
  styleUrls: ['./company-data.component.css']
})
export class CompanyDataComponent implements OnInit {

  public companyData: CompanyData;

  @Output()
  public uploadClick: EventEmitter<String> = new EventEmitter<String>();
  @Output()
  public popupEvent: EventEmitter<String> = new EventEmitter<String>();

  constructor(private auth: AuthService, private http: HttpClient, private router: Router) {
    this.companyData = new CompanyData();
    this.companyData.companyname = this.auth.companyName;
  }

  ngOnInit() {

    this.http.get( globals.URL + '/getcompanydata?token=' + this.auth.token)
      .subscribe(getCompanyDataResult => {
        if (getCompanyDataResult['status'] === 'OK') {
          const result: any = getCompanyDataResult['result'];
          for (const key in result) {
            if (result.hasOwnProperty(key)) {
              if (result[key] === 'true' || result[key] === 'false') {
                this.companyData[key] = ( result[key] === 'true' ? true : false);
              } else {
                this.companyData[key] = result[key];
              }
            }
          }
          this.companyData.convertDatesToNumbers();
          this.auth.companyName = this.companyData.companyname;
        }
      });
  }

  public addNewDate() {
    this.companyData.torlesi_hataridok.push(new DateNumbers());
  }

  public saveForm() {
    this.companyData.setupDates();
    const company_data_json: any = JSON.stringify(this.companyData);

    this.http.post( globals.URL + '/save?token=' + this.auth.token, company_data_json)
    .subscribe(loginResult => {
     if (loginResult['status'] === 'OK') {
      // convertDatesToNumbers
      this.popupEvent.emit('SIKERES MENTÉS TÖRTÉNT!');
     } else if (loginResult['status'] === 'ERROR') {
      alert(loginResult['errors']);
     }
    }, error => {
      console.log(error);
    });
  }

  public logout() {
    this.auth.isLogined = false;
    this.auth.token = null;
    localStorage.removeItem('currentUser');
    this.router.navigateByUrl('/');
  }

  public uploadButtonClick() {
    this.uploadClick.emit('');
  }

  public fileDownloadClick() {
    const download_url = globals.URL + '/filedownload?token=' + this.auth.token;
    window.open(download_url);
  }

}
