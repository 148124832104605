import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';
import { Http } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as globals from './globals';

export class CompanyResult {

  public id: string;
  public companyname: string;
  public taxnumber: string;
  public location: string;
  public registrationnumber: string;
  public emailaddress: string;
  public phonenumber: string;
  public website: string;

  public nev: boolean;
  public allando_lakcim: boolean;
  public szuletesi_nev: boolean;
  public tart_hely: boolean;
  public anyja_neve: boolean;
  public erzekeny_adat: boolean;
  public mail: boolean;
  public genetikai: boolean;
  public telefon: boolean;
  public biometrikus: boolean;
  public szuletesi_hely: boolean;
  public szuletesi_ido: boolean;
  public eu: boolean;
  public bank: boolean;

  constructor() {
    this.nev = true;
    this.allando_lakcim = true;
    this.szuletesi_nev = true;
    this.tart_hely = true;
    this.anyja_neve = true;
    this.erzekeny_adat = true;
    this.mail = false;
    this.genetikai = true;
    this.telefon = true;
    this.szuletesi_hely = true;
    this.szuletesi_ido = true;
    this.biometrikus = false;
    this.eu = true;
    this.bank = true;
  }

  public toString = (): string => {
    return `${this.companyname}`;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  public companyDatas: Observable<CompanyResult[]>;

  constructor(private http: HttpClient) {
  }

  public search(keyword: string) {
    if (!keyword || keyword.length < 2) {
      return of([]);
    } else {
      return this.http.get<CompanyResult[]>(globals.URL + '/search?keyword=' + keyword);
    }
  }

  /*public manSearch(test: string) {
    this.http.get(globals.URL + '/search?keyword=' + test)
      .subscribe(searchResult => {
        var result = searchResult as CompanyResult[];
        this.companyDatas = of(result);
      }, error => {
        console.log(error);
      });
  }*/

}
