export class DateNumbers {
  public ev: number;
  public honap: number;
  public nap: number;

  constructor() {
    this.ev = 2000;
    this.honap = 1;
    this.nap = 1;
  }

}
