import { DateNumbers } from './DateNumbers';

export class CompanyData {

  public companyname: string; // *
  public taxnumber: string; // *
  public registrationnumber: string; // cégjegyzékszám *
  public location: string; // székhely *
  public mailaddress: string; // levelezési cím
  public emailaddress: string; //
  public ceo: string; // ügyvezető neve *
  public contactname: string; // kapcsolattartó neve *
  public contactinfo: string; // kapcsolattartó elérhetősége
  public contractnumber: string; // szerződésszám
  public website: string;
  public uploaded_file: string;

  public nev: boolean;
  public allando_lakcim: boolean;
  public szuletesi_nev: boolean;
  public tart_hely: boolean;
  public anyja_neve: boolean;
  public erzekeny_adat: boolean;
  public mail: boolean;
  public genetikai: boolean;
  public telefon: boolean;
  public biometrikus: boolean;
  public szuletesi_hely: boolean;
  public szuletesi_ido: boolean;
  public eu: boolean;
  public bank: boolean;

  public adatkezelo_ceg: string;
  public adatkezelo: boolean;
  public adatfeldolgozo: boolean;
  public kozos_adatkezelo: boolean;
  public adatkezelo_iranyitasa_alatt_kotelezo: boolean;
  public adatkezelo_iranyitasa_alatt_kezelo: boolean;
  public adatfeldolgozo_iranyitas_alatt_kezelo: boolean;

  public torveny_alapjan: boolean;
  public beleegyezesen_alapul: boolean;

  public beleegyezes: string;
  public beleegyezes_ev: number;
  public beleegyezes_honap: number;
  public beleegyezes_nap: number;

  public dm_level: boolean;
  public jogszabalyi_megfeleles: boolean;
  public regisztraciohoz_kotott: boolean;
  public szallitasi_informacio: boolean;

  public egyeb: string;

  public torlesi_hataridok: Array<DateNumbers>;
  public torlesi_hataridok_datum: Array<string>;

  public torlesi_hatarido: string;
  public torlesi_hatarido_ev: number;
  public torlesi_hatarido_honap: number;
  public torlesi_hatarido_nap: number;

  public adattovabbitas_hatarido: string;
  public adattovabbitas_hatarido_ev: number;
  public adattovabbitas_hatarido_honap: number;
  public adattovabbitas_hatarido_nap: number;

  public eun_belul: boolean;
  public eun_kivul: boolean;
  public egtn_belul: boolean;
  public egtn_kivul: boolean;

  public adattovabbitas_egyeb: string;

  public kiertesites: string;
  public kiertesites_ev: number;
  public kiertesites_honap: number;
  public kiertesites_nap: number;

  public kiertesites_nem: boolean;
  public kiertesites_celja: string;

  public tovabbitas_nev: boolean;
  public tovabbitas_allando_lakcim: boolean;
  public tovabbitas_szuletesi_nev: boolean;
  public tovabbitas_tart_hely: boolean;
  public tovabbitas_anyja_neve: boolean;
  public tovabbitas_erzekeny_adat: boolean;
  public tovabbitas_email: boolean;
  public tovabbitas_genetikai: boolean;
  public tovabbitas_telefon: boolean;
  public tovabbitas_biometrikus: boolean;
  public tovabbitas_szuletesi_hely: boolean;
  public tovabbitas_eu: boolean;
  public tovabbitas_szuletesi_ido: boolean;
  public tovabbitas_bank: boolean;

  public torles_kerese: string;
  public torles_kerese_ev: number;
  public torles_kerese_honap: number;
  public torles_kerese_nap: number;

  public zarolas_kerese: string;
  public zarolas_kerese_ev: number;
  public zarolas_kerese_honap: number;
  public zarolas_kerese_nap: number;

  public helyesbites: string;
  public helyesbites_ev: number;
  public helyesbites_honap: number;
  public helyesbites_nap: number;

  public tajekoztatas_kerese: string;
  public tajekoztatas_kerese_ev: number;
  public tajekoztatas_kerese_honap: number;
  public tajekoztatas_kerese_nap: number;

  constructor() {
    this.nev = true;
    this.allando_lakcim = true;
    this.szuletesi_nev = true;
    this.tart_hely = true;
    this.anyja_neve = true;
    this.erzekeny_adat = true;
    this.biometrikus = true;
    this.bank = true;

    this.torlesi_hataridok = new Array<DateNumbers>();
    this.torlesi_hataridok.push(new DateNumbers());
  }

  public getDate(propertyName: string) {
    if ( this[propertyName + '_ev'] && this[propertyName + '_honap'] && this[propertyName + '_nap']) {
      return this[propertyName + '_ev'] + '-' + this[propertyName + '_honap'] + '-' + this[propertyName + '_nap'];
    }
    return null;
  }

  public getDateFromDateNumbers(dateNumbers: DateNumbers): string {
    if (dateNumbers.ev && dateNumbers.honap && dateNumbers.nap) {
      return dateNumbers.ev.toString() + '-' +  dateNumbers.honap.toString() + '-'  +
         dateNumbers.nap.toString();
    }
    return null;
  }

  private convertDateNumberFromString(datestring: string) {
    const dateNumber = new DateNumbers();
    const datum_stringek: string[] = datestring.split('-');
    dateNumber.ev = parseInt( datum_stringek[0], 10 );
    dateNumber.honap = parseInt( datum_stringek[1], 10 );
    dateNumber.nap = parseInt( datum_stringek[2], 10);
    return dateNumber;
  }

  public setDateNumberProperties(propertyname: string) {
    if (this[propertyname] && this[propertyname].toString() != null) {
      const a = this.convertDateNumberFromString( this[propertyname]);
      this[propertyname + '_ev'] = a.ev;
      this[propertyname + '_honap'] = a.honap;
      this[propertyname + '_nap'] = a.nap;
    }
  }

  /* lekérdezés utáni beállítás */
  public convertDatesToNumbers() {
    // ev honap nap értékek beállítása
    this.setDateNumberProperties('tajekoztatas_kerese');
    this.setDateNumberProperties('helyesbites');
    this.setDateNumberProperties('zarolas_kerese');
    this.setDateNumberProperties('kiertesites');
    this.setDateNumberProperties('torles_kerese');
    this.setDateNumberProperties('adattovabbitas_hatarido');
    this.setDateNumberProperties('beleegyezes');
    this.setDateNumberProperties('torlesi_hatarido');

    this.torlesi_hataridok = new Array<DateNumbers>();

    for (let i = 0; i < this.torlesi_hataridok_datum.length; i++) {
      const dateNumber: DateNumbers = this.convertDateNumberFromString(this.torlesi_hataridok_datum[i]);
      this.torlesi_hataridok.push(dateNumber);
    }
  }

  /* felküldés előtti beállítás */
  public setupDates() {
    this.tajekoztatas_kerese = this.getDate('tajekoztatas_kerese');
    this.helyesbites = this.getDate('helyesbites');
    this.zarolas_kerese = this.getDate('zarolas_kerese');
    this.kiertesites = this.getDate('kiertesites');
    this.torles_kerese = this.getDate('torles_kerese');
    this.adattovabbitas_hatarido = this.getDate('adattovabbitas_hatarido');
    this.beleegyezes = this.getDate('beleegyezes');
    this.torlesi_hatarido = this.getDate('torlesi_hatarido');
    this.torlesi_hataridok_datum = new Array<string>();
    for (let i = 0; i < this.torlesi_hataridok.length; i++) {
      const datum_string: string = this.getDateFromDateNumbers(this.torlesi_hataridok[i]);
      this.torlesi_hataridok_datum.push(datum_string);
    }
  }

}
