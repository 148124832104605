import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as globals from '../globals';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  public content = `
 <h1>Miért is kell adatvédelmi nyilvántartás?</h1>
 <p>GDPR előírja, hogy magának a rendeletnek való megfelelés bizonyítása érdekében minden adatkezelő köteles nyilvántartást vezetni a hatásköre alapján végzett adatkezelési tevékenységekről.</p>
 <p>A GDPR 30. cikk (5) bekezdése biztosított kivételt, amely szerint a 250 főnél kevesebb személyt foglalkoztató szervezetek bizonyos feltételekkel mentesülhetnek a nyilvántartás vezetése alól. A nyilvántartás vezetésétől nem lehet eltekinteni, ha az adatkezelés „valószínűsíthetően kockázattal jár” vagy nem „alkalmi jellegű”. Az adatkezelés „alkalmi jellegének” megítélésére a NAIH állásfoglalása alapján a következőt kell tekinteni:</p>
 <p>...a nyelvtani értelmezést lehet alapul venni. Továbbá figyelembe lehet venni azt is, hogy a GDPR 5. cikk (2) bekezdésében írt „elszámoltathatóság elve” alapján az adatkezelőnek képesnek kell lennie a megfelelés igazolására, amelyhez a nyilvántartás adatai megfelelő alapul szolgálnak. Mindezek alapján a Hatóság álláspontja szerint e mentesített körbe valóban csak az egyedi, egyszeri, kivételes adatkezeléseket folytató kis- és középvállalkozások tartozhatnak, más esetben nem lehet eltekinteni a nyilvántartás vezetésétől.</p>
 <p>Tehát jól látható, hogy a nyilvántartási kötelezettség nagyon kevés cég esetében mellőzhető, ellenben mindenki más számára kötelező.</p>
 <p>Ezen kötelezettségében segítenénk Önt jelen megoldásunkkal, ezzel is időt nyerve a cégének és a munkavállalóinak, akiknek eleget kell tennie.</p><h1>Kinek hasznos?</h1>
 <p>Megoldásunk hasznos mind az Adatkezelőknek (cégeknek), mind pedig az Érintetteknek, tehát a magánszemélyeknek is, hiszen egy helyen fellelhető lesz az összes adattípus, amit az egyes cégek kezelnek a természetes személyekről. A cégek részéről regisztráció után rögzíthetők adatok a nyilvántartásba. Az Érintettek regisztráció nélkül megtekinthetik, hogy az adott cég milyen típusú adatokat rögzít magánszemélyekről.</p>
 <p>A cégek az adatvédelmi nyilvántartás segítségével eleget tudnak tenni a kötelezettségüknek, ugyanakkor riportot tudnak készíttetni a rendszerrel, így néhány kattintással áttekinthetik a kezelt adatok körét és naprakész információt kaphatnak.</p><h1>Miért kezelje adatvédelmi nyilvántartását nálunk?</h1>
 <p>Ha Ön adatkezelő, akkor rendszerünkkel eleget tehet a törvényi kötelezettségének, nyilvántartást vezethet a kezelt és tárolt adatokról. Regisztráció után lehetősége nyílik egy biztonságos és zárt rendszerben való nyilvántartás vezetésére.</p>
 <p>Emellett magánszemélyként regisztráció nélkül is tájékozódhatunk arról, hogy egy-egy cég milyen adatokat tárol a vele kapcsolatban álló magánszemélyekről.</p>
 <h1>Rendszerünk használatának menete</h1>
 <p>CÉGEKNEK</p>
 <p>1. REGISZTRÁCIÓ - Cégek ügyvédi irodánkkal történt szerződéskötés után regisztrálhatnak a felületre. A sikeres folyamatot lezárva az előzetesen megadott e-mail címre érkezik egy visszaigazoló levél a regisztráció sikerességéről.</p>
 <p>2. BELÉPÉS A FELÜLETRE – A visszaigazoló e-mail után a cég kapcsolattartója a CÉGEKNEK menüpont alatt, a belépési adatok megadása után léphet be oldalunkra.</p>
 <p>3. ADATKEZELÉSI INFORMÁCIÓK MEGADÁSA – Az oldalra történő belépés után kérjük adja meg a megjelenő felületen végighaladva, hogy milyen típusú adatokat kezel a cég. Ezeket az információkat láthatják az érintettek, amikor rákeresnek a cégére. Ennek köszönhetően a cég adatkezelése átlátható és megismerhető.</p>
 <p>MAGÁNSZEMÉLYEKNEK</p>
 <p>1. KERESÉS – Természetes személyként regisztráció nélkül lehetőségünk van arra, hogy ellenőrizzük, a regisztrált cégek milyen típusú adatokat kezelnek és tárolnak.</p>
 <p>Az ÜGYFELEKNEK menüpont alatt, a keresőmezőbe írja be annak a cégnek a nevét, melynek adatkezeléséről többet szeretne tudni. Amennyiben a cég regisztrált a felületen, megjelenik a cégnév. A kattintás után megjelenő felületen láthatja a cég adatait, illetve hogy milyen típusú adatokat kezel.</p>
  `;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.http.get(globals.URL + '/getdata?id=4', { responseType: 'text' })
      .subscribe(getDataResult => {
        this.content = getDataResult.toString();
      }, error => {
        console.log(error);
      });
  }

}
