import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth.service';
import * as globals from '../globals';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  @ViewChild('content') content: any;

  public modalBodyContent: string;
  public fileUploadFormVisible: boolean;

  public url: string = globals.URL;

  public filename: string;

  public uploaded = false;

  public fileToUpload: File = null;

  public successMessage = '';
  public errorMessage = '';

  constructor(public modalService: NgbModal, public auth: AuthService, public httpClient: HttpClient) {
  }

  ngOnInit() {
  }

  public popup(htmlContent: string, fileupload: boolean = false): Promise<any> {
    this.modalBodyContent = htmlContent;
    this.fileUploadFormVisible = fileupload;
    this.uploaded = false;
    this.errorMessage = '';
    this.successMessage = '';
    this.fileToUpload = null;
    this.filename = '';
    const ref: NgbModalRef = this.modalService.open(this.content, { windowClass: 'script-select-modal', size: 'lg', centered: true });
    return ref.result;
  }

  public fileChangeEvent(files: FileList) {
    this.fileToUpload = files.item(0);
    this.uploaded = true;
  }

  public chooseFile() {
    const userfile = document.getElementById('userfile');
    userfile.click();
  }

  public uploadClick() {
    this.uploaded = false; // feltöltés gomb letiltva
    const formData: FormData = new FormData();
    formData.append('userfile', this.fileToUpload, this.fileToUpload.name);

    this.httpClient.post(globals.URL + '/fileupload?token=' + this.auth.token , formData)
    .subscribe(fileUploadResult => {
      if (fileUploadResult['status'] === 'OK') {
        this.successMessage = 'Sikeresen feltöltődött a fájl, most már bezárhatja az ablakot!';
      } else {
        this.errorMessage = fileUploadResult['errors'].toString();
      }
      this.uploaded = true; // feltöltés gomb engedélyezve
    }, error => {
      console.log(error);
      this.errorMessage = ( error.message ? error.message : error.toString() );
      this.uploaded = true; // feltöltés gomb engedélyezve
    });

  }

}
