import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as globals from '../globals';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.css']
})
export class ImpressumComponent implements OnInit {

  public content = `
<p>Az mitkezelnekrolam.hu-al kapcsolatban:</p>
<p><strong>Szolgáltató megnevezése, elérhetőségei:</strong></p>
<p>Dr. Bánkuti Gábor egyéni ügyvéd&nbsp;</p>
<p>Székhely: 3000 Hatvan, Madách utca 9/A.</p>
<p>Fiókiroda: 1182 Budapest, Tarkő utca 1.&nbsp;</p>
<p>Nyilvántartó kamara: Heves Megyei Ügyvédi Kamara</p>
<p><strong>Tárhely szolgáltató, adatai:</strong></p><p>Dotroll Zrt.</p>
<p>Cím: 1148 Budapest, Fogarasi út 3-5., Magyarország</p>
<p>Adószám: 13962982-2-42</p><p>Telefon: +36-1-432-3232</p>
<p>Fax: +36-1-432-3231</p><p>E-mail: support@dotroll.com</p>
<p><strong>Weblap design-ért illetve a biztonságáért felelős:</strong></p>
<p>DEVWING</p><p>E-mail cím: info@devwing.hu</p>
<p><strong>Jogi nyilatkozat:</strong></p>
<p>Jelen honlapot a DEVWING tartja fenn. A jogi nyilatkozat az MITKEZELNEKROLAM.HU oldalra vonatkozik.</p>
<p>Az ezen a honlapon elhelyezett valamennyi információ csupán tájékoztatásul szolgál, az nem minősül jogi tanácsnak és ezen információk átadása nem hoz létre a Dr. Bánkuti Gábor egyéni ügyvéd (a továbbiakban: Iroda) és a honlap látogatója között jogviszonyt.</p>
<p>A honlap szerkesztői minden lehetséges eszközzel arra törekszenek, hogy biztosítsák a weboldalon megjelenő információk és tanácsok pontosságát, ennek ellenére esetlegesen előfordulhatnak véletlen hibák a weboldal tartalmában. Kérjük, amennyiben hibát tapasztalnak, jelezzék nekünk az elérhetőségeink egyikén.</p>
<p><strong>Személyes adatok kezelése</strong></p>
<p>Jelen honlap nem tüntet fel az ügyfeleinek kilétével kapcsolatos információt. A honlapon keresztül, vagy az azon feltüntetett e-mail címet használva az Iroda részére juttatott bármely információ, ötlet vagy koncepció az Iroda tulajdonába megy át, azt az Iroda szabadon felhasználhatja.</p>
<p>Amennyiben a honlapon keresztül, vagy az azon feltüntetett e-mail címet használva személyes adatokat (pl. név, cím, e-mail cím, telefonszám, faxszám) ad meg, azokat az Iroda kezeli és archiválja. Az adatszolgáltatás önkéntes. Kérelmére az Iroda tájékoztatást ad az általa kezelt adatairól, valamint bármikor kérheti adatai helyesbítését, illetve törlését.</p>
  `;

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    this.http.get(globals.URL + '/getdata?id=2', { responseType: 'text' })
    .subscribe(getDataResult => {
      this.content = getDataResult.toString();
    }, error => {
      console.log(error);
    });
  }

}
