import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import fontawesome from '@fortawesome/fontawesome';
import faTrashAlt from '@fortawesome/fontawesome-free-regular/';
import { AuthService } from './auth.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as globals from './globals';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [ NgbCarouselConfig ]
})
export class AppComponent  implements OnInit {
  title = 'app';

  public site: Number = 0;

  public email = 'drbankutigabor@gmail.com';
  public telefon = '+36 70 386 4005';

  constructor(public auth: AuthService, private router: Router, route: ActivatedRoute,
    private config: NgbCarouselConfig, private http: HttpClient) {
    fontawesome.library.add(faTrashAlt);
    config.interval = 3000;
    config.pauseOnHover = false;
  }

  public logout() {
    this.auth.isLogined = false;
    this.auth.token = null;
    localStorage.removeItem('currentUser');
    this.router.navigateByUrl('/');
    this.site = 0;
  }

  public onActivate(e) {
    const routeName = e.constructor.name;
    if (routeName.toLowerCase().indexOf('clients') > -1) {
      this.site = 1;
    } else if (routeName.toLowerCase().indexOf('companies') > -1) {
      this.site = 2;
    } else {
      this.site = 0;
      setTimeout(() => {
        $('.carousel').carousel();
      }, 500);
    }
  }

  ngOnInit() {

    this.http.get(globals.URL + '/getdata?id=5', { responseType: 'text' })
    .subscribe(getDataResult => {
      this.email = getDataResult.toString().replace(/<[^>]*>/g, '');
    }, error => {
      console.log(error);
    });

    this.http.get(globals.URL + '/getdata?id=6', { responseType: 'text' })
    .subscribe(getDataResult => {
      this.telefon = getDataResult.toString().replace(/<[^>]*>/g, '');
    }, error => {
      console.log(error);
    });

    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.scroll-top').fadeIn();
        } else {
            $('.scroll-top').fadeOut();
        }
    });

    $('.scroll-top').click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 100);
        return false;
    });
  }

}
