import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as globals from '../globals';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  public content = `
  <h1>lorum impsum</h1>
  <p>
  sadsads sa dsad sa ds dsa
  sa dsa dsa dsa dsa dsa das sa
  </p>
  <h1>sadasdsadsa</h1>
  `;

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    this.http.get(globals.URL + '/getdata?id=1', { responseType: 'text' })
      .subscribe(getDataResult => {
        this.content = getDataResult.toString();
      }, error => {
        console.log(error);
      });
  }

}
